<template>
  <div class="about">

    <v-container fluid>

      <v-row  align="center" justify="center">
        <v-col xs="12" sm="12" md="12" lg="8" xl="8">
          <h2>クレジットカード決済</h2>

          <v-card
              class="mx-auto mb-5"
              outlined
          >
            <v-card-title>カート内商品</v-card-title>

            <v-card-text>
              <v-row align="center" justify="center" v-for="item in cartItems" :key="item.no">

                <v-col xs="12" sm="12" md="12" lg="2" xl="2" class="pb-0">
                  <v-img :src="item.imgMain" style=""></v-img>
                </v-col>

                <v-col xs="12" sm="12" md="12" lg="10" xl="10" class="pb-0 cart-box">
                  <v-card-subtitle>商品名：{{item.itemName}}</v-card-subtitle>
                  <v-card-text>金額：¥{{item.price | toThousandFilter}}</v-card-text>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>


          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
          >

<!--            <v-card
                class="mx-auto mb-5"
                outlined
            >
              &lt;!&ndash;<v-card-title>支払方法選択</v-card-title>&ndash;&gt;
              <v-card-text>

                <v-text-field
                    v-model="couponcode"
                    label="クーポンコード"
                    :rules="couponcodeRules"
                    required
                ></v-text-field>
                <p>※クーポンコードをお持ちでないお客様は、空欄のままお進みください</p>


                <v-spacer></v-spacer>
                <v-select
                    v-model="point"
                    :items="points"
                    :rules="pointRules"
                    label="ポイントのご利用"
                    required
                ></v-select>
                <p>現在の保有ポイントは「500」です。<br>
                  ※1ポイント1円</p>
                <p>利用しない場合、ポイントは使われず商品のご配送後に新たにポイントが加算されます。</p>
                <v-spacer></v-spacer>
              </v-card-text>


                <v-card-title>お支払い方法の選択</v-card-title>
              <v-card-text>
                <v-radio-group v-model="radios" :mandatory="false">
                  <v-radio label="銀行振り込み" value="radio-1"></v-radio>
                  <p class="radio-text">指定口座へご入金頂きます。</p>
                  <v-radio label="商品代引" value="radio-2"></v-radio>
                  <p class="radio-text">配送業者がお届けの際に、購入代金を配達員の方にお支払いください。</p>
                  <v-radio label="クレジット" value="radio-3"></v-radio>
                  <v-img style="width:165px; height:28px;" src="../../../assets/credit_all.png"></v-img>
                  <p class="radio-text">「VISA、MasterCard、ダイナース、JCB、AMEX」のご利用が可能です。</p>
                  <v-radio label="コンビニ" value="radio-4"></v-radio>
                  <v-img style="width:315px; height:128px;" src="../../../assets/cvs_all.png"></v-img>
                  <p class="radio-text">コンビニでのご利用が可能です。<br>
                    <span class="red&#45;&#45;text">（前払い）ご注文から1週間以内にお支払いください。
                    商品はご入金後の発送となります。</span></p>
                </v-radio-group>
             </v-card-text>


            </v-card>-->

            <v-card
                class="mx-auto mb-5 cardForm"
                outlined
            >
              <div id="card-element">
                <!-- Elements will create input elements here -->
              </div>

              <!-- We'll put the error messages in this element -->
              <div id="card-errors" ref="cardErrors" role="alert"></div>


            </v-card>

            <div align="center" class="ma-5">
              <v-btn
                  color="#e60044"
                  dark
                  depressed
                  tile
                  x-large
                  class="mr-2"
                  @click="go2('confirm')"
              >
                前に戻る
              </v-btn>

              <v-btn
                  color="#e60044"
                  dark
                  depressed
                  tile
                  x-large
                  class="mr-2"
                  @click="payment()"
              >
                購入する
              </v-btn>

            </div>

          </v-form>

        </v-col>
      </v-row>

    </v-container>


    <v-snackbar
        v-model="snackbar"
        :timeout="snackbarTimeout"
        color="error"
        top
    >
      {{ snackbarText }}
      <v-btn
          text
          @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from 'axios';
import orders from "@/api/orders";
import { loadStripe } from "@stripe/stripe-js";

// テスト環境を判別
let testMode = 0;
let stripeCode = 'pk_live_51ISc0ADwdqU269ZhCn9o2wd0Bl7qvIjCtZdvjh4rRS6YXjKz1kisSQxSbQcEnKW41qpIrkB91NQP4b5SVYaPGi6k005X5zKF63'
let stripeUrl = 'https://cheflog.jp/api_cart/stripe_create.php'
if(process.env.NODE_ENV == 'development') {
  testMode = 1;
  stripeCode = 'pk_test_51ISc0ADwdqU269ZhZXqesJw5D6yJjiaSBJDBP1QqFO7GuAnEGgKqRQfZKo4TDpiuYwA4ggIrReXjbjDOnmsgjuJO00ysOA0qPM'
  stripeUrl = 'http://test.cheflog.jp/api_cart/stripe_create.php'
}


export default {
  props: {
    para: String,
  },
  data: () => ({
    testMode,
    axios,
    mailRegist: null,
    result: 'none',
    valid: false,

    // カート情報
    orderInfo:[],     // 注文情報
    cartItems:[],     // 商品情報
    itemsPrice:0,     // 商品合計金額
    deliveryPrice:0,  // 送料
    commission:0,     // 手数料
    totalPrice:0,     // 請求合計金額

    stripe: "",
    credit_token_id:null,
    card: null,

    /** ________________________________________ snackbar設定 **/
    snackbar: false,
    snackbarText: '',			//	表示テキスト
    snackbarTimeout: 3000,		//	タイムアウト秒数


  }),
  async mounted() {
    this.stripe = await loadStripe(stripeCode)
    const elements = this.stripe.elements()
    this.card = elements.create("card");
    this.card.mount("#card-element");
    this.card.addEventListener('change', ({error}) => {
      const displayError = this.$refs.cardErrors
      if (error) {
        displayError.textContent = error.message;
      } else {
        displayError.textContent = '';
      }
    });
  },
  created(){

    // 商品情報を取得
    this.cartItems = this.$store.state.cartItems

    // 注文情報
    this.orderInfo = this.$store.state.orderInfo

    // 受注情報を取得
    this.itemsPrice     = this.cartItems.reduce((p, x) => p + x.price, 0)   // 合計金額を取得
    this.deliveryPrice  = this.$store.state.orderInfo.deliveryRito?1100:0   // 送料の取得
    this.commission     = 0                                                 // 手数料の取得

    // 請求合計金額
    this.$nextTick(function (){
      this.totalPrice = this.itemsPrice + this.deliveryPrice + this.commission
    })


    // TODO 一時対応
    //this.payment  = 1
    this.payName  = 'クレジット'

  },
  computed: {

  },

  methods: {
    /** __________________________________________ stripe通信 **/
    payment: async function() {
    //async payment() {
      let res = await axios.post(stripeUrl, {
            amount: this.totalPrice,                                              // 金額
            name: this.$store.state.orderInfo.name,                               // 名前
            login_id: this.$store.state.orderInfo.login_id,                       // ログインID
            customer_payment_id: this.$store.state.orderInfo.customer_payment_id, // stripeID
          },
          {
            headers: {
              "Content-Type": "application/json",
              // これはcognitoでサインしていれば、「Amplify.Auth.currentAuthenticatedUser().then((data) => {」から取れるものをstateに入れてるところから取得
              //'Authorization': store.state.user.signInUserSession.idToken.jwtToken,
            }
          }
      )
      //const client_secret = res.data.client_secret;


      const client_secret = res.data.clientSecret;

      //client_secretを利用して（確認情報をStripeに投げて）決済を完了させる
      const confirmRes = await this.stripe.confirmCardPayment(client_secret, {
        payment_method: {
          // card: this.props.elements.getElement('card'),
          card: this.card,
          billing_details: {
           // name: res.data.metadata.username,
          }
        }
      });

      // 決済処理完了
      if(!confirmRes.error) {
        if (confirmRes.paymentIntent.status === "succeeded" ||
            confirmRes.paymentIntent.status === "requires_capture") {

          console.log(confirmRes.paymentIntent.id)

          // 決済IDの保存
          this.orderInfo.credit_token_id = confirmRes.paymentIntent.id

          // 注文処理
          this.order()

        }
      }
    },
    /** __________________________________________________ 注文処理 **/
    order(){

      // 情報の統合
      this.orderInfo.orderFlag  = 1                         // 送信フラグ
      this.orderInfo.token      = this.$store.state.token   // トークン情報
      this.orderInfo.cart       = this.cartItems            // カート情報

      // 注文処理
      orders.update(this.updateResult, this.orderInfo);


    },
    /** __________________________________________________ 注文結果の取得 **/
    updateResult (res) {

      this.result = res.data.result;

      // エラー処理
      if (!this.result || (this.result == "error")) {

        this.go2('error')
      }

      // 更新成功
      if (this.result == "success") {

        // TODO 注文番号を引き継いだ方が良い？
        this.go2('fin')

      }

    },
    /** __________________________________________________ 指定画面への移動 **/
    go2(mode) {
      this.$parent.mode = mode
      this.$router.push("/cart/" + mode);
    },
    /** __________________________________________________ 情報の保存 **/
    saveGo2(mode){
      this.$store.state.orderInfo.cartPaymentPanel  = 1                     // 支払い方法が設定されたか(1 or 0)
      this.$store.state.orderInfo.couponcode        = this.couponcode		    // クーポンコード
      this.$store.state.orderInfo.point             = this.point				    // ポイント
      this.$store.state.orderInfo.payment           = 1	                    // 支払い方法
      this.$store.state.orderInfo.payName           = this.payName	        // 支払い方法名称
      this.$store.state.orderInfo.credit_token_id   = this.credit_token_id  // クレジット決済トークン

      //storeに保存
      this.$store.commit("SET_ORDER_INFO", this.$store.state.orderInfo)

      this.go2(mode)

    },
  }
}
</script>

<style scoped>
@import "~@/styles/form.css";
@import "~@/styles/stripe_global.css";

.cardForm{
  max-width: 410px;
}

.radio-text {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
}
</style>
