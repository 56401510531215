<template>
  <div>
    <v-row  align="center" justify="center">
      <v-col xs="12" sm="12" md="12" lg="8" xl="8">
        <h2>カート</h2>

          <v-card
              class="mx-auto mb-5"
              outlined
          >
            <v-card-title>カート内商品</v-card-title>

            <v-card-text>
              <v-row align="center" justify="center" v-for="item in cartItems" :key="item.no">

                <v-col xs="12" sm="12" md="12" lg="2" xl="2" class="pb-0">
                  <v-img :src="item.imgMain" style=""></v-img>
                </v-col>

                <v-col xs="12" sm="12" md="12" lg="8" xl="8" class="pb-0 cart-box">
                  <v-card-subtitle>商品名：{{item.itemName}}</v-card-subtitle>
                  <v-card-text>金額：¥{{item.price | toThousandFilter}}</v-card-text>
                </v-col>


                <v-col xs="12" sm="12" md="12" lg="2" xl="2" class="pb-0">
                  <v-card-text class="pt-0 pb-0">
                    <!--                    個数：
                                        <v-text-field
                                            single-line
                                            outlined
                                            label="1"
                                            required
                                            class="quantity"
                                        ></v-text-field>-->
                    <v-btn @click="removeItem(item.no)">削除</v-btn>
                  </v-card-text>
                </v-col>


              </v-row>
            </v-card-text>
          </v-card>
          <div align="center" class="ma-5">
            <v-btn
                color="#e60044"
                dark
                depressed
                tile
                x-large
                class="mr-2"
                :to="beforePath"
            >
              前に戻る
            </v-btn>
            <v-btn
                color="#e60044"
                dark
                depressed
                tile
                x-large
                @click="go2('login')"
            >
              次へ進む
            </v-btn>
          </div>

      </v-col>
    </v-row>
  </div>
</template>

<script>


export default {
  data() {
    return {

      // 商品情報
      cartItems:[],
      beforePath:'',

    }
  },
  created() {

    // 商品情報を取得
    this.cartItems = this.$store.state.cartItems

    // カート前のページ
    this.beforePath = this.$store.state.orderInfo.beforePath

    console.log(this.$store.state.orderInfo)

  },
  methods:{
    // ___________________________________________________________ 明細の削除
    removeItem(no) {

      // indexを取得
      function getIndex(value, arr, prop) {
        for(var i = 0; i < arr.length; i++) {
          if(arr[i][prop] === value) {
            return i;
          }
        }
        return -1; //値が存在しなかったとき
      }
      const cartItemIndex = getIndex(no, this.cartItems, 'no')

      // 対象商品を削除
      this.cartItems.splice(cartItemIndex, 1)

      // storeに保存
      this.$store.commit("ADD_CART_ITEMS", this.cartItems)
    },
    // ___________________________________________________________ 指定画面への移動
    go2(mode){
      this.$parent.mode = mode
      this.$router.push("/cart/"+mode);
    }
  },

}
</script>

<style scoped>
.quantity {
  display: inline-block;
  width: 3em;
  height: 4em;
  overflow: hidden;
  vertical-align: middle;
  margin-right: 16px;
}
</style>
