<template>
  <div>
    <v-container class="" fluid>

      <div class="about">
        <v-container class="pa-0" fluid>
          <v-row  align="center" justify="center">
            <v-col sm="12" md="10" lg="8" xl="8" align="center" style="margin-top: 100px">

              <p class="newLine">
                ご購入ありがとうございます<br>
                注文確認のメールをお送り致しました。
              </p>

            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-container>

  </div>
</template>

<script>

export default {
  data() {
    return {


    }
  },
  created() {

    // 情報を空にする
    this.$store.state.cartItems = []
    this.$store.commit("ADD_CART_ITEMS", this.$store.state.cartItems)
    this.$store.state.orderInfo = {}
    this.$store.commit("SET_ORDER_INFO", this.$store.state.orderInfo)

  },
  methods: {

  }
}
</script>

<style scoped>
@import "~@/styles/form.css";
</style>
