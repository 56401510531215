<template>
  <div>

      <v-row  align="center" justify="center">
        <v-col cols="12" xs="12" sm="12" md="10" lg="4" xl="4" align="center" fluid>
          <v-layout text-left wrap class="pa-5">
            <h2>ログイン</h2>
            <v-flex xl12 lg12 md12 sm12 xs12>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                    v-model="mailaddress"
                    :rules="mailaddressRules"
                    label="メールアドレス"
                    required
                ></v-text-field>

                <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    label="パスワード"
                    type="password"
                    required
                ></v-text-field>


                <div align="center" class="ma-5">
                  <v-btn
                      color="#e60044"
                      dark
                      depressed
                      tile
                      x-large
                      class="mr-2"
                      @click="go2('')"
                  >
                    前に戻る
                  </v-btn>
                  <v-btn
                      color="#e60044"
                      dark
                      depressed
                      tile
                      x-large
                      @click="login"
                  >
                    ログイン
                  </v-btn>
                </div>


                <div class="text-right mt-3">
                  <router-link  to="/register"><a>会員登録</a></router-link><br>
<!--                  <a>パスワードを忘れた方はこちら</a>-->
                </div>
              </v-form>
            </v-flex>

            <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" color="error" top>
              {{ snackbarText }}
              <v-btn text @click="snackbar = false">Close</v-btn>
            </v-snackbar>
          </v-layout>
        </v-col>
      </v-row>

  </div>
</template>

<script>
import axios from "axios";
let loginUrl =process.env.VUE_APP_API_ORIGIN+"/api_mypage/api";


export default {
  data() {
    return {
      valid: false,

      // スネーク設定
      snackbar: false, //snackbar設定
      snackbarText: "", //	表示テキスト
      snackbarTimeout: 3000, //	タイムアウト秒数

      // ログイン項目情報
      mailaddress: null,		// メールアドレス
      mailaddressRules: [
        v => !!v || 'メールアドレスを入力してください',
        v => /^([a-zA-Z0-9])+([a-zA-Z0-9._+-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9._-]+)+$/.test(v) || 'メールアドレスを正しく入力してください',
      ],
      password: null,		// パスワード
      passwordRules: [
        v => !!v || 'パスワードを入力してください',
        v => v != undefined && v.length >= 6 || 'パスワードを6文字から24文字でご入力ください。',
        v => v != undefined && v.length <= 24 || 'パスワードを6文字から24文字でご入力ください。',
      ],

    }
  },
  created(){
    /** __________________________________________________________________________ 情報の判別 */
    // トークンチェック
    if(this.$store.state.token) {
      this.go2('user')
    }

  },
  methods: {
    // ___________________________________________________________ 指定画面への移動
    go2(mode){
      this.$parent.mode = mode
      this.$router.push("/cart/"+mode);
    },
    /** __________________________________________________________________________ ログイン */
    login() {

      if (this.$refs.form.validate()) {

        let params = new URLSearchParams();
        params.append("mailaddress", this.mailaddress);
        params.append("password", this.password);

        axios
            .post(loginUrl, params)
            .then(res => {
              this.result = res.data.result;

              if (this.result == "error") {
                switch (res.data.errorCode) {
                  case "001":
                    this.snackbarText = "メールアドレス、パスワードをご確認ください";
                    break;
                  case "002":
                    this.snackbarText = "メールアドレス、パスワードをご確認ください";
                    break
                  default:
                    this.snackbarText = res.data.errorCode;
                }
                this.snackbar = true;
                stop();
              }

              if (this.result == "success") {
                // vuex store値にトークンを保存
                this.$store.commit("SET_TOKEN", res.data.token);


                //会員情報の取得
                this.usermail 		= res.data.usermail;	// メールアドレス
                this.userName 		= res.data.name1;		// 会員名
                this.userTel 		= res.data.tel;			// 電話番号
                this.userId 		= res.data.login_id;	// ユーザID

                //ページ変更
                this.go2('user')

              }
            })
            .catch(error => {
              this.snackbarText = error;
              this.snackbar = true;
            });
      }else {
        console.log("err")
      }
    },
  },
}
</script>
<style scoped>
@import "~@/styles/style.css";

</style>
