<template>
  <div>
    <v-container fluid>
      <v-stepper :value="step">
        <v-stepper-header>
          <v-stepper-step step="1">商品確認</v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="2">ログイン・ご購入情報入力</v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">配送先選択・入力・配送方法選択</v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="4">ご確認</v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="5">クレジットカード決済</v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="6">完了</v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <cart v-if="(mode=='profile' || mode=='' || mode==undefined )" :mode="mode" />

      <login  v-if="(mode=='login')" :mode="mode" />

      <user  v-if="(mode=='user')" :mode="mode" />

      <delivery  v-if="(mode=='delivery')" :mode="mode" />


      <confirm  v-if="(mode=='confirm')" :mode="mode" />

      <payment  v-if="(mode=='payment')" :mode="mode" />

      <fin  v-if="(mode=='fin')" :mode="mode" />

      <error  v-if="(mode=='error')" :mode="mode" />

    </v-container>
  </div>
</template>

<script>
	import cart from "./components/cart"
	import login from "./components/login"
	import user from "./components/user"
  import delivery from "./components/delivery"
  import payment from "./components/payment"
	import confirm from "./components/confirm"
	import fin from "./components/fin"
	import error from "./components/error"

  export default {
    name:"mypage",
    components:{
      cart,
      login,
      user,
      delivery,
      payment,
      confirm,
      fin,
      error,
    },
    props: {
      modePara:String
    },
    data() {
      return {

        mode:'',

        // loading
        loading:true,


      }
    },
    computed:{
      step:function(){
        switch(this.mode) {
          // カート
          case 'cart': case '': default: case undefined:
            return 1

          // ログイン
          case 'login':
            return 2

          // 申込者情報
          case 'user':
            return 2

          // 配送先情報
          case 'delivery':
            return 3

          // 確認画面
          case 'confirm':
            return 4

            // 支払い方法
          case 'payment':
            return 5

            // クレジット
          case 'credit':
            return 5

          // 完了画面
          case 'fin':
            return 6

          // エラー
          case 'error':
            return 0

        }

      }


    },
    created() {
      this.mode = this.modePara
    },


  }
</script>
<style scoped>

</style>
