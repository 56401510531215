<template>
  <div>
    <v-container class="" fluid>

      <div class="about">
        <v-container class="pa-0" fluid>
          <v-row  align="center" justify="center">
            <v-col sm="12" md="10" lg="8" xl="8" align="center" style="margin-top: 100px">

              <p class="newLine">
                エラーが発生しました。<br>

              </p>

            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-container>

  </div>
</template>

<script>

export default {
  data() {
    return {


    }
  },
  created() {

  },
  methods: {

  }
}
</script>

<style scoped>
@import "~@/styles/form.css";
</style>
