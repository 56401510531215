<template>
  <div>
    <!-- ___________________________________________________________________________ 確認画面 -->
    <v-container class="" fluid>

      <v-row  align="center" justify="center">
        <v-col xs="12" sm="12" md="12" lg="8" xl="8">
          <h2>確認画面</h2>

          <v-card
              class="mx-auto mb-5"
              outlined
          >
            <v-card-title>カート内商品</v-card-title>

            <v-card-text>
              <v-row align="center" justify="center" v-for="item in cartItems" :key="item.no">

                <v-col xs="12" sm="12" md="12" lg="2" xl="2" class="pb-0">
                  <v-img :src="item.imgMain" style=""></v-img>
                </v-col>

                <v-col xs="12" sm="12" md="12" lg="10" xl="10" class="pb-0 cart-box">
                  <v-card-subtitle>商品名：{{item.itemName}}</v-card-subtitle>
                  <v-card-text>金額：¥{{item.price | toThousandFilter}}</v-card-text>
                </v-col>




              </v-row>
            </v-card-text>
          </v-card>
          <v-card
              class="mx-auto mb-5"
              outlined
          >
            <v-card-title>購入者情報</v-card-title>
            <v-card-text>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>お名前</v-list-item-title>
                  <v-list-item-subtitle>{{orderInfo.name}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>ふりがな</v-list-item-title>
                  <v-list-item-subtitle>{{orderInfo.furigana}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>生年月日</v-list-item-title>
                  <v-list-item-subtitle>{{orderInfo.birthday}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>性別</v-list-item-title>
                  <v-list-item-subtitle>{{orderInfo.sex}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>郵便番号</v-list-item-title>
                  <v-list-item-subtitle>{{orderInfo.zip}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>都道府県名</v-list-item-title>
                  <v-list-item-subtitle>{{orderInfo.city}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>市区町村</v-list-item-title>
                  <v-list-item-subtitle>{{orderInfo.address1}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>以下住所</v-list-item-title>
                  <v-list-item-subtitle>{{orderInfo.address2}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>電話番号</v-list-item-title>
                  <v-list-item-subtitle>{{orderInfo.tel}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              </v-card-text>
          </v-card>
          <v-card
              class="mx-auto mb-5"
              outlined
          >
              <v-card-title>配送先情報</v-card-title>
            <v-card-text>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>お名前</v-list-item-title>
                  <v-list-item-subtitle>{{orderInfo.d_name}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>ふりがな</v-list-item-title>
                  <v-list-item-subtitle>{{orderInfo.d_furigana}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>生年月日</v-list-item-title>
                  <v-list-item-subtitle>{{orderInfo.d_birthday}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>性別</v-list-item-title>
                  <v-list-item-subtitle>{{orderInfo.d_sex}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>郵便番号</v-list-item-title>
                  <v-list-item-subtitle>{{orderInfo.d_zip}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>都道府県名</v-list-item-title>
                  <v-list-item-subtitle>{{orderInfo.d_city}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>市区町村</v-list-item-title>
                  <v-list-item-subtitle>{{orderInfo.d_address1}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>以下住所</v-list-item-title>
                  <v-list-item-subtitle>{{orderInfo.d_address2}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>電話番号</v-list-item-title>
                  <v-list-item-subtitle>{{orderInfo.d_tel}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>備考(のし表記)</v-list-item-title>
                  <v-list-item-subtitle>{{orderInfo.note}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

<!--              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>配送方法</v-list-item-title>
                  <v-list-item-subtitle>ヤマト運輸・佐川急便</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>-->
            </v-card-text>
          </v-card>
          <v-card
              class="mx-auto mb-5"
              outlined
          >
          <v-card-text>
<!--              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>ポイントの利用</v-list-item-title>
                  <v-list-item-subtitle>なし</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>-->

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>支払い方法</v-list-item-title>
                  <v-list-item-subtitle>クレジット</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

            </v-card-text>



          </v-card>

          <div align="center" class="ma-5">
            <v-btn
                color="#e60044"
                dark
                depressed
                tile
                x-large
                class="mr-2"
                @click="go2('delivery')"
            >
              前に戻る
            </v-btn>
            <v-btn
                color="#e60044"
                dark
                depressed
                tile
                x-large
                @click="saveGo2('payment')"
            >
              次へ進む
            </v-btn>
          </div>


        </v-col>
      </v-row>
    </v-container>

  </div>
</template>


<script>
export default {
  data() {
    return {

      // 商品情報
      cartItems:[],

      // 注文情報
      orderInfo:[],

    }
  },
  created() {

    // 商品情報を取得
    this.cartItems = this.$store.state.cartItems

    // 注文情報
    this.orderInfo = this.$store.state.orderInfo


  },
  methods: {
    /** __________________________________________ 指定画面への移動 **/
    go2(mode) {
      this.$parent.mode = mode
      this.$router.push("/cart/" + mode);
    },
    /** __________________________________________________ 情報の保存 **/
    saveGo2(mode){

      // 確認が設定されたか(1 or 0)
      this.$store.state.orderInfo.cartConfirmPanel  = 1

      //storeに保存
      this.$store.commit("SET_ORDER_INFO", this.$store.state.orderInfo)

      this.go2(mode)

    },
  }
}
</script>

<style scoped>
@import "~@/styles/form.css";
</style>
