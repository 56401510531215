<template>
  <div>

    <v-row  align="center" justify="center">
      <v-col xs="12" sm="12" md="12" lg="8" xl="8">
        <h2>申込者情報</h2>

    <div>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >

        <v-card
            class="mx-auto mb-5"
            outlined
        >
          <v-card-title>お客様情報</v-card-title>
          <v-card-text>
            <v-text-field
                v-model="member.name"
                label="お名前"
                :rules="nameRules"
                required
            ></v-text-field>

            <v-text-field
                v-model="member.furigana"
                label="ふりがな"
                :rules="furiganaRules"
                required
            ></v-text-field>

            <v-menu
                ref="birthdayMenu"
                v-model="birthdayMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >

              <template v-slot:activator="{ on }">
                <v-text-field
                    v-model="member.birthday"
                    :rules="birthdayRules"
                    label="生年月日"
                    prepend-icon="fas fa-birthday-cake"
                    readonly
                    v-on="on"

                ></v-text-field>
              </template>
              <v-date-picker
                  ref="picker"
                  v-model="member.birthday"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1950-01-01"
                  locale="ja"
                  @change="birthDaySave"
              ></v-date-picker>
            </v-menu>

            <v-select
                v-model="member.sex"
                :items="sexs"
                :rules="sexRules"
                label="性別"
                required
            ></v-select>

            <v-text-field
                v-model="member.zip"
                :rules="zipRules"
                label="郵便番号"
                required
                @blur="fetchAddress(member.zip)"
                placeholder="1010021"
            ></v-text-field>

            <v-select
                v-model="member.city"
                :items="citys"
                :rules="cityRules"
                label="都道府県名"
                required
            ></v-select>

            <v-text-field
                v-model="member.address1"
                :rules="address1Rules"
                label="市区町村"
                required
            ></v-text-field>

            <v-text-field
                v-model="member.address2"
                :rules="address2Rules"
                label="以下住所"
                required
            ></v-text-field>

            <v-text-field
                v-model="member.tel"
                :rules="telRules"
                label="電話番号"
                placeholder="090-0000-0000"
                required
            ></v-text-field>

          </v-card-text>
        </v-card>

        <v-card
            class="mx-auto mb-5"
            outlined
        >
          <v-card-text>
            <v-card-title>お届け先</v-card-title>
            <v-select
                v-model="member.sendtoName"
                :items="sendtos"
                :rules="sendtoRules"
                label="お届け先の選択"
                required
            ></v-select>
          </v-card-text>
        </v-card>


        <div align="center" class="ma-5">
          <v-btn
              color="#e60044"
              dark
              depressed
              tile
              x-large
              class="mr-2"
              @click="go2('')"
          >
            前に戻る
          </v-btn>
          <v-btn
              color="#e60044"
              dark
              depressed
              tile
              x-large
              @click="saveGo2('delivery')"
          >
            次へ進む
          </v-btn>
        </div>

      </v-form>
    </div>
    <!-- ____________________________________________________________________________ 確認 -->

      </v-col>
    </v-row>
  </div>


</template>

<script>
import axiosJsonpAdapter from 'axios-jsonp'
let url 		= process.env.VUE_APP_API_ORIGIN+"/api_register/api";
const ZIPCODE_API_URL = 'https://zipcloud.ibsnet.co.jp/api/search'
import member from "@/api/members.js";
import axios from "axios";


export default {
  data() {
    return {
      axios,
      url,
      valid: false,
      loading:true,
      mode:'',

      /** ________________________________________ 項目設定 **/
      member:[],
      // お客様情報
      nameRules: [
        v => !!v || 'お名前を入力してください',
      ],
      // ふりがな
      furiganaRules: [
        v => !!v || 'ふりがなを入力してください',
        v => /^[あ-ん゛゜ぁ-ぉゃ-ょー「」、]+/.test(v) || 'ふりがなを「ひらがな」でご入力ください。',
      ],
      // 生年月日
      birthdayMenu: false,
      birthdayRules: [
        v => !!v || '生年月日を入力してください',
      ],
      // 性別
      sexRules: [
        v => !!v || '性別を選択してください',
      ],
      sexs:['男性','女性'],
      // 郵便番号
      zipRules: [
        v => /^\d{1,7}$/.test(v) || '郵便番号を「半角数字」7桁でご入力ください。',
        v => !!v || '郵便番号を入力してください',
      ],
      // 都道府県名
      citys: ['北海道','青森県','岩手県','宮城県','秋田県','山形県','福島県','茨城県','栃木県','群馬県','埼玉県','千葉県','東京都','神奈川県','新潟県','富山県','石川県','福井県','山梨県','長野県','岐阜県','静岡県','愛知県','三重県','滋賀県','京都府','大阪府','兵庫県','奈良県','和歌山県','鳥取県','島根県','岡山県','広島県','山口県','徳島県','香川県','愛媛県','高知県','福岡県','佐賀県','長崎県','熊本県','大分県','宮崎県','鹿児島県','沖縄県'],
      cityRules: [
        v => !!v || '都道府県名を選択してください',
      ],
      // 市区町村
      address1Rules: [
        v => !!v || '市区町村を入力してください',
      ],
      // 以下住所
      address2Rules: [
        v => !!v || '以下住所を入力してください',
      ],
      // 電話番号
      telRules: [
        v => !!v || '電話番号を入力してください',
        v => /^\d{1,4}-\d{1,4}-\d{1,4}$/.test(v) || '電話番号を「半角数字」10～11桁でご入力ください。',
        v => v != undefined && v.length <= 14 || '電話番号を「半角数字」10～11桁でご入力ください。',
      ],

      // お届け先
      sendtoRules: [
        v => !!v || 'お届け先を選択してください',
      ],
      sendtos:['上記の住所に送る','新しい住所を入力する'],

    }
  },
  watch: {
    birthdayMenu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  created() {

    // 会員情報の取得
    member.fetch(this.memberInfo, this.$store.state.token);
  },
  methods: {
    // ___________________________________________________________ 指定画面への移動
    go2(mode){
      this.$parent.mode = mode
      this.$router.push("/cart/"+mode);
    },
    /** __________________________________________________________________________ 会員情報の取得 */
    async memberInfo(info) {

      if(!info.data[0]) {
        this.$store.commit("SET_TOKEN", null);
        this.$router.push("/cart/login");
      }

      // 情報のセット
      if (this.$store.state.orderInfo.cartUserPanel > 0) {
        // 情報がある場合のみstoreから取得
        this.member = this.$store.state.orderInfo;
      }else {
        this.member = info.data[0];
      }


      this.$nextTick(()=>{
        this.loading = false

      })

    },
    /** __________________________________________________誕生日の一時保存 **/
    birthDaySave (value) {
      this.$refs.birthdayMenu.save(value)
    },
    /** __________________________________________________ 郵便番号処理 **/
    async fetchAddress(zip) {

      // 郵便番号のバリデーションチェック
      const reg = /^\d{7}$/
      if (!reg.test(zip)) return

      // 住所apiを叩く
      const res = await axios.get(ZIPCODE_API_URL, {
        adapter: axiosJsonpAdapter,
        params: {
          zipcode: zip
        }
      })

      // 存在しない郵便番号でapiを叩くと200以外のステータスが返ってくる
      if (res.status !== 200) return

      // 返却されたデータを挿入する
      this.member.city 		  = res.data.results[0].address1
      this.member.address1 	= res.data.results[0].address2 + res.data.results[0].address3
    },
    /** __________________________________________________ 情報の保存 **/
    saveGo2(mode){

      if (this.$refs.form.validate()) {

        this.$store.state.orderInfo.cartUserPanel         = 1                               // 申込情報設定
        this.$store.state.orderInfo.sendtoName            = this.member.sendtoName          // 配送先
        this.$store.state.orderInfo.memberType            = this.member.memberType          // 会員種別
        this.$store.state.orderInfo.name                  = this.member.name                // お名前
        this.$store.state.orderInfo.furigana              = this.member.furigana            // ふりがな
        this.$store.state.orderInfo.birthday              = this.member.birthday            // 生年月日
        this.$store.state.orderInfo.sex                   = this.member.sex                 // 性別
        this.$store.state.orderInfo.zip                   = this.member.zip                 // 郵便番号
        this.$store.state.orderInfo.city                  = this.member.city                // 都道府県名
        this.$store.state.orderInfo.address1              = this.member.address1            // 市区町村
        this.$store.state.orderInfo.address2              = this.member.address2            // 以下住所
        this.$store.state.orderInfo.tel                   = this.member.tel                 // 電話番号

        this.$store.state.orderInfo.usermail              = this.member.usermail            // メールアドレス
        this.$store.state.orderInfo.login_id              = this.member.login_id            // ログインID
        this.$store.state.orderInfo.customer_payment_id   = this.member.customer_payment_id // ログインID

        //storeに保存
        this.$store.commit("SET_ORDER_INFO", this.$store.state.orderInfo)

        this.go2(mode)
      }else {
        window.scrollTo({
          top: 0,
        });
      }

    },

  }

}
</script>
<style scoped>

</style>
