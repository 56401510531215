import axios from "axios";

//接続URL
let url = process.env.VUE_APP_API_ORIGIN+"/api_orders/";
let url_update = process.env.VUE_APP_API_ORIGIN+"/api_orders/index.php";

export default {
	/**
	 * 情報取得
	 * @param callback
	 * @param token
	 * @returns {Promise<void>}
	 */
	fetch: async function(callback,token) {
		await axios.get(url + "?token="+token).then(response => callback(response));
	},
	/**
	 * 情報更新
	 * @param callback
	 * @param params
	 * @returns {Promise<void>}
	 */
	update: async function(callback,params) {
		await axios.post(url_update, params)
			.then(response => callback(response));
	},
};
